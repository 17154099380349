import React, { useEffect } from "react";
import MuxPlayer from "@mux/mux-player-react"; 
import "./GoDeeperCarousel.css";

const gd_base_file_directory = '/back_stories/';

function GoDeeperCard({episodeId, cardInfo}) {

  function resetMuxVideo(event) {
    event.target.load();
  }

  let videoInfo = cardInfo["videoInfo"] || '';

  let descriptionWithNumberHTML = "";
  if (cardInfo.descriptionHTML.startsWith('<p class="text-italic">')) {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace('<p class="text-italic">','<p class="text-italic"><span class="gdc-card-id" style="font-style:normal;">'+cardInfo.number+'</span> ');
  } else if (cardInfo.descriptionHTML.startsWith('<p class="gd-slideshow-card-title">')) {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace('<p class="gd-slideshow-card-title">','<p class="gd-slideshow-card-title"><span class="gdc-card-id" style="font-style:normal;">'+cardInfo.number+'</span> ');
  } else {
    descriptionWithNumberHTML = cardInfo.descriptionHTML.replace("<p>",'<p><span class="gdc-card-id">'+cardInfo.number+'</span> '); 
  }
 

  if (videoInfo === '') { // image card
    let filePath = gd_base_file_directory+episodeId+'/'+cardInfo.filename;
    return (
      <div className="gdc-card" key={cardInfo.id} id={cardInfo.id}>
        <div className="gdc-card-image-wrapper">
          <img
              className="gdc-card-image"
              src={filePath}
              alt={cardInfo.altText}
              loading="lazy"
            />
        </div>
        <div className="gdc-caption-text" dangerouslySetInnerHTML={{ __html: descriptionWithNumberHTML}}></div>
      </div>
    );
  } else { // video card
    return (
      <div className="gdc-card" key={cardInfo.id} id={cardInfo.id}>
          <MuxPlayer
              streamType="on-demand"
              playbackId={videoInfo.playbackId}
              thumbnailTime={videoInfo.thumbnailTime}
              title={videoInfo.title}
              metadata={videoInfo.metadata}
              defaultHiddenCaptions="true"
              primaryColor="#FFFFFF"
              accent-color="#000000"
              onEnded={resetMuxVideo}
              />
        <div className="gdc-caption-text" dangerouslySetInnerHTML={{ __html: descriptionWithNumberHTML}}></div>
      </div>
    );
  }
}

function GoDeeperCarousel({episodeId, slideshowsList, requestedCardId}) {
  const cardsLists = slideshowsList.map(slideshowData => slideshowData.slidesData.map(slideInfo => <GoDeeperCard episodeId={episodeId} cardInfo={slideInfo} /> ) );
  const cards = cardsLists.flat();
  const carouselId = "carousel-"+episodeId;


  useEffect(() => {
    setTimeout(() => { /* Timeout needed in order to work on Safari browsers */
      showRequestedCarouselCard();
    }, 30);
  });
  
  function hideCarousel() {
    document.getElementById(carouselId).close();
  };

  function showRequestedCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let requestedCard = carouselCards[0]; // first card by default
    for (let card of carouselCards) { // find the specified card
      if (card.id === requestedCardId) {
        requestedCard = card;
        break;
      }
    }

    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    carouselScroller.scrollLeft = requestedCard.offsetLeft;
  }

  function showPreviousCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    let currentPosition = Math.trunc(carouselScroller.scrollLeft+20); /* sometimes current position is half a pixel less then left of card, add a buffer */
    let currentCardIndex = "0";
    for (let cardIndex in carouselCards) { // find which card is showing; cardIndex is a string
        if (carouselCards[cardIndex].offsetLeft <= currentPosition) {
            currentCardIndex = cardIndex;
        }
    }
    currentCardIndex = parseInt(currentCardIndex);
    if (currentCardIndex === 0) { // at first card, exit carousel
      hideCarousel();
    } else {
      let previousCard = carouselCards[currentCardIndex-1];
      carouselScroller.scrollLeft = previousCard.offsetLeft;
    }
  }

  function showNextCarouselCard() {
    let carouselCards = document.getElementsByClassName("gdc-card");
    let carouselScroller = document.getElementsByClassName("gdc-scrolling-wrapper")[0];
    let currentPosition = Math.trunc(carouselScroller.scrollLeft+20); /* sometimes current position is half a pixel less then left of card, add a buffer */
    let currentCardIndex = "0";
    for (let cardIndex in carouselCards) { // find which card is showing
        if (carouselCards[cardIndex].offsetLeft <= currentPosition) {
            currentCardIndex = cardIndex;
        }
    }
    currentCardIndex = parseInt(currentCardIndex);
    if (currentCardIndex === (carouselCards.length-1)) { // at last card, exit carousel
      hideCarousel();
    } else {
      let nextCard = carouselCards[currentCardIndex+1];
      carouselScroller.scrollLeft = nextCard.offsetLeft;
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'ArrowLeft') {
      showPreviousCarouselCard();
    } else if (event.key === 'ArrowRight') {
      showNextCarouselCard();
    } else if (event.key === 'Escape') {
      hideCarousel();
    }
  };

  return (
    <dialog className="gdc-popup" id={carouselId} onKeyDown={handleKeyDown} tabIndex="0">
      <span class="gdc-close" onClick={hideCarousel}>&times;</span>
      <div class="gdc-arrow-button gdc-left-button" onClick={showPreviousCarouselCard}><span class="gdc-left-arrow"></span></div>
      <div class="gdc-arrow-button gdc-right-button" onClick={showNextCarouselCard}><span class="gdc-right-arrow"></span></div>
      <div className="gdc-scrolling-wrapper">
          { cards }
        </div>
    </dialog>
  );
}

export default GoDeeperCarousel;