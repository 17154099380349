// src/components/Home.js
import {React, useState, useEffect} from 'react';
import './Home.css'
import { motion } from "framer-motion"
import { chaptersInfo } from './homeData';
import { Link, useLocation } from "react-router-dom";
import MuxPlayer from "@mux/mux-player-react"; 
import BlueCrab from '../Components/Assets/crab_crab.svg';
import YouTube from 'react-youtube';
import FullPagePloyButtonIntro from '../Components/Assets/fullpage-play-button-Intro.png';
import { setActive } from '../Components/Scrollbar/Scrollbar';

export let globalLocation = '';

export function CrabAnimation () {

    const bounceVariants = {
        initial: {
            y: 20,
        },
        animate: {
            y: [10, -30],
            transition: {
            duration: 0.1,
            type: "spring",
            stiffness: 300,
            repeat: 4,
            repeatType: "reverse",
            delay: 3.2,
            },
        },
    };

    const animationVariants = {
        initial: {
            x:0,
            rotate: 0,
        },
        animate: {
            x: ["-150%", "30%"],
            rotate: ["-5deg", "5deg", "-5deg", "5deg", "-5deg", "5deg",  "0deg"],
            transition: {
            duration: 1, 
            ease: "backOut",
            delay: 2,
            }
        },
    };

}

export function FirstVideoBlock(chapterInfo) {
    const descriptionHTML = { __html: chapterInfo.descriptionHTML }; 
      
    const bounceVariants = {
        initial: {
            y: 20,
        },
        animate: {
            y: [10, -30],
            transition: {
            duration: 0.1,
            type: "spring",
            stiffness: 300,
            repeat: 4,
            repeatType: "reverse",
            delay: 3.2,
            },
        },
    };

    const animationVariants = {
        initial: {
            x:0,
            rotate: 0,
        },
        animate: {
            x: ["-150%", "30%"],
            rotate: ["-5deg", "5deg", "-5deg", "5deg", "-5deg", "5deg",  "0deg"],
            transition: {
            duration: 1, 
            ease: "backOut",
            delay: 2,
            }
        },
    };

    const [playing, setPlaying] = useState(false);
    const [youtube, setYoutube] = useState(null);


    const opts = {
        height: '390',
        width: '640',
        playerVars: {
        autoplay: playing ? 1 : 0,
        },
    };

    const handlePlay = () => {
        setPlaying(true);
        if (youtube) {
        youtube.playVideo();
        }
        setPlaying(!playing);
    };

    const resetYouTubeVideo = (event, videoId) => {
        if(event.data === 0) {   // video ended
            event.target.cueVideoById(videoId);
            setPlaying(false);    
        }
    };

    return (
            <motion.div className="chapters-wrapper" id = "introduction"
            variants={bounceVariants}
            initial="initial"
            animate="animate"
            >

            <motion.img
                variants={animationVariants}s
                initial="initial"
                animate="animate"
                src={BlueCrab} alt="Carb" className="crab"
            />

            <motion.div class="chapter-0 parent"
            initial={{ width: '80%' , y: "-20%", borderRadius: "30px"}}
            whileInView={{ width: '100%', borderRadius: "0px", y: "0%"}}
            viewport={{ once: false, amount: 0.5}}
            transition = {{staggerChildren: 2}}
            onViewportEnter={() => 
                {globalLocation = `introduction`;
                setActive(globalLocation);
                console.log(`home location: ${globalLocation}`)
                }}
            >

                <motion.div class="top-div text-width-center"
                    initial={{ y: "-5%"}}
                    whileInView={{ y: "0%"}}
                    viewport={{ once: false, amount: 0.8}}
                    >
                
                        <div>
                            <h2 class="chapter-number-text" style={{ fontSize: '1.3rem'}}>The <span style={{ fontStyle: 'italic', fontWeight: 300}}>Play Seriously</span> Series</h2>
                            <h2 class="chapter-title-text">{chapterInfo.name}</h2>
                        </div>

                        <div class="chapter-subtext">
                            <div class ="video-container">

                                <YouTube videoId="vWLmR3R575M" opts={opts}
                                    onReady={(event) => {setYoutube(event.target);}}
                                    onStateChange={(event) => resetYouTubeVideo(event, "vWLmR3R575M")}
                                />
                                {playing ? null : (
                                    <img
                                        src={FullPagePloyButtonIntro}
                                        alt="Custom play button"
                                        className = "play-trigger"
                                        onClick={handlePlay}
                                    />
                                )}
                            </div>

                        <div class="video-text" dangerouslySetInnerHTML={descriptionHTML}></div>
                        <Link to="/BackStories/ep0"><button className='button'>Back Stories on Introduction</button></Link>
                    </div>
                </motion.div>
        </motion.div>
    </motion.div>
    );
}

export function VideoBlock(chapterInfo){
    const chapterNum = chapterInfo.chapterNumber;
    const descriptionHTML = { __html: chapterInfo.descriptionHTML };  
    const youtubeID = chapterInfo.videoLink;
    const backStoriesUp = chapterInfo.backStories

    const [playing, setPlaying] = useState(false);
    const [youtube, setYoutube] = useState(null);

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: playing ? 1 : 0,
        },
    };

    const handlePlay = () => {
        setPlaying(true);
        if (youtube) {
          youtube.playVideo();
        }
      };

    const resetYouTubeVideo = (event, videoId) => {
        if(event.data === 0) {   // video ended
            event.target.cueVideoById(videoId);
            setPlaying(false);    
        }
    };

    return (
        <div class="chapters-wrapper" id = {chapterInfo.divID}>
            <motion.div className={`chapter-${chapterNum} 'parent`} 
            initial={{ width: '80%' , y: "-10%", borderRadius: "30px"}}
            whileInView={{ width: '100%', borderRadius: "0px", y: "0%"}}
            // whileTap={{y: "-20%"}}
            viewport={{ once: false, amount: 0.5}}
            transition = {{staggerChildren: 2}}
            onViewportEnter={() => 
                {globalLocation = `ep${chapterNum}`;
                setActive(globalLocation);
                // console.log(`home location: ${globalLocation}`
                window.location.hash = `#ep${chapterNum}`
                }}
            >

            <motion.div class="top-div text-width-center"
                initial={{ y: "-10%", borderRadius: "30px"}}
                whileInView={{ borderRadius: "0px", y: "0%"}}
                viewport={{ once: false, amount: 0.5}}>
                    <div>
                        <h2 class="chapter-number-text" style={{ fontSize: '1.3rem'}}>Episode {chapterInfo.chapterNumber}</h2>
                        <h2 class="chapter-title-text" >{chapterInfo.name}</h2>
                    </div>

                        <div class="chapter-subtext">
                            <div class ="video-container">
                            <YouTube className= "video-frame" 
                                videoId={youtubeID} opts={opts}
                                onReady={(event) => {setYoutube(event.target);}}
                                onStateChange={(event) => resetYouTubeVideo(event, youtubeID)}
                            />
                            {playing ? null : (
                                <img href="#ep1"
                                    src={require(`../Components/Assets/fullpage-play-button-Ep${chapterNum}.png`)}
                                    alt="Custom play button"
                                    className = "play-trigger"
                                    onClick={(event) => {
                                        handlePlay();
                                    }}
                                />
                            )}
                            </div>
                        <div class="video-text" dangerouslySetInnerHTML={descriptionHTML}></div>
                        {/* <Link to={ '/BackStories/'+chapterInfo.divID }><button className='button'>Back Stories for Episode {chapterInfo.chapterNumber}</button></Link> */}
                        {backStoriesUp && (
                            <Link to={'/BackStories/'+chapterInfo.divID}>
                                <button className='button'>Back Stories for Episode {chapterInfo.chapterNumber}</button>
                            </Link>
                        )}

                    </div>
                </motion.div>
        </motion.div>
    </div>
    );
}

export function TrailerBlock(chapterInfo){
    const chapterNum = chapterInfo.chapterNumber;
    const descriptionHTML = { __html: chapterInfo.trailerHTML };
    const trailerInfo = chapterInfo.trailerInfo;

    function resetMuxVideo(event) {
        event.target.load();
    }

    return (
        <div class="chapters-wrapper" id = {chapterInfo.divID} >
            <motion.div className={`chapter-${chapterNum} parent`}
            initial={{ width: '80%' , y: "-5%", borderRadius: "30px"}}
            whileInView={{ width: '100%', borderRadius: "0px", y: "0%"}}
            viewport={{ once: false, amount: 0.5}}
            transition = {{staggerChildren: 2}}
            onViewportEnter={() => 
                {globalLocation = `ep${chapterNum}`;
                setActive(globalLocation);
                console.log(`home location: ${globalLocation}`)
                }}
            >

                <motion.div class="top-div text-width-center"
                    initial={{ y: "-10%", borderRadius: "30px"}}
                    whileInView={{ borderRadius: "0px", y: "0%"}}
                    viewport={{ once: false, amount: 0.5}}>
                    <div>
                        <h2 class="chapter-number-text" style={{ fontSize: '1.3rem'}}>Episode {chapterInfo.chapterNumber} <span class="text-italic">Trailer</span></h2>
                        <h2 class="chapter-title-text">{chapterInfo.name}</h2>
                        <br></br>
                    </div>

                    <div class="chapter-subtext">
                        <div class ="video-container-mux">
                        <MuxPlayer
                            streamType="on-demand"
                            playbackId={trailerInfo.playbackId}
                            title={trailerInfo.title}
                            metadata={trailerInfo.metadata}
                            defaultHiddenCaptions="true"
                            primaryColor="#FFFFFF"
                            accentColor={trailerInfo.accentColor}
                            poster={trailerInfo.posterURL}
                            onEnded={resetMuxVideo}
                            />
                        </div>

                        <div class="video-text" dangerouslySetInnerHTML={descriptionHTML}></div>
                        <div style={{ fontSize: '1.3rem', marginBottom: '20px' }}>Full episode on Thursday, {chapterInfo.releaseDate}, 12PM EST</div>
                        <button class="button" onClick={() => window.open('https://play-seriously.ck.page/4993a23f75', '_self')} >Join Email Alert</button>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export function UpNextBlock(chapterInfo){
    const chapterNum = chapterInfo.chapterNumber;

    return(
    <div class="chapters-wrapper"  id = {chapterInfo.divID} >
        <motion.div className={`chapter-${chapterNum} ${UpNextBlock}`}
        initial={{ width: '80%', y: "-5%" , borderRadius: "30px"}}
        whileInView={{ width: '100%', borderRadius: "0px", y: "0%"}}
        viewport={{ once: false, amount: 0.5}}
        onViewportEnter={() => 
                {globalLocation = `ep${chapterNum}`;
                setActive(globalLocation);
                console.log(`home location: ${globalLocation}`)
                }}
        >

        <div class="middle-div text-width-center">
            <h2 class="next-number-text">Episode {chapterInfo.chapterNumber}</h2>
            <h2 class="next-title-text">{chapterInfo.name}</h2>
            <h2 class="next-release-text">Coming soon!</h2>
            <h2 class="next-release-text">Trailer, Tuesday, {chapterInfo.trailerDate}, 12PM EST</h2>
            <h2 class="next-release-text">Full episode, Thursday, {chapterInfo.releaseDate}, 12PM EST</h2>
            <br></br>
            <ul>
                {/* <li><button class="button" >Trailer</button></li> */}
                <li><button class="button" onClick={() => window.open('https://play-seriously.ck.page/4993a23f75', '_self')} >Join Email Alert</button></li>
                {/*<div>
                    {scriptLoaded && <div>Script loaded!</div>}
                </div>*/}
                {/* <li><button class="button " >Follow Us On...</button></li> */}
            </ul>
        </div>
        </motion.div>
    </div>

    )
}


const Home = ({featuredEpisode}) => {
    let location = useLocation();
    let hash = location.hash;
    if (hash.endsWith("anchor")) { // trim off "anchor" word, so no need for "ep1anchor" div
        hash = hash.slice(0,hash.length-6);
    }

    function scrollToAnchor() {
        if (hash.length>1) { // anchor provided
            const element = document.getElementById(hash.substring(1));
            if (element) { // if element exists
                element?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        } else if (featuredEpisode !== "") { // request for current episode
            const element = document.getElementById(featuredEpisode);
            if (element) { // if element exists
                element?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }

    useEffect(() => {
        scrollToAnchor(); // runs only on first render
      }, []);

    return (
        <div class = "main scroll-snap">
            <div class="wrapper">
                <motion.div class="chapters-wrapper parent"
                        onViewportEnter={() => 
                        {globalLocation = `home`;
                        setActive(globalLocation);
                        console.log(`home location: ${globalLocation}`)
                        }}
                >
                    <div class="middleDiv home-subtext">
                        <h2 class="home-title-text text-width-center" id="home">PLAY SERIOUSLY!</h2>
                        <p class="text-width-center" style={{fontWeight: 600}}>
                        A 7 part series 
                        <br></br>
                        1 year in production.
                        30 years in preparation.</p>
                        <br></br> 
                        <div class="subtext-light text-width-center">
                            <span>
                                Join me, Professor David Wallace, as I explore thoughts on design, teaching, and aspirations for excellence &mdash; told through the lens of 
                                    MIT’s iconic course <a href="https://2023.2009.mit.edu/" target="_blank" rel="noopener noreferrer" 
                                style={{ fontWeight: 'bold', color: 'var(--yellow)' }}>2.009</a>.
                            </span>
                        </div>
                    </div>
                </motion.div>

                {FirstVideoBlock(chaptersInfo[0])}
                {VideoBlock(chaptersInfo[1])}
                {VideoBlock(chaptersInfo[2])}
                {VideoBlock(chaptersInfo[3])}
                {VideoBlock(chaptersInfo[4])}
                {UpNextBlock(chaptersInfo[5])}

                <div class="chapters-wrapper">
                <div class="chapters-wrapper parent">
                <div class="credit text-width-center">
                    <div class="middleDiv">
                        <h2 class="home-title-text" id="home">PLAY SERIOUSLY!</h2>
                    </div>
                    <p class="credit-title"> Funded by</p>
                    <p class="credit-detail">A. Neil and Jane Pappalardo Mechanical 
                    Engineering Curriculum Development Fund</p>
                    <br></br>
                    <p class="credit-title">Featuring</p>
                    <p class="credit-detail">Professor David Wallace <br></br>Students and Staff of 2.009</p>
                    <br></br>
                    <p class="credit-title">Produced by</p>
                    <p class="credit-detail"><a href="https://innerparakeet.com" target="_blank" rel="noopener noreferrer" 
                                style={{ fontWeight: 'bold', color: 'var(--yellow)' }}>InnerParakeet</a></p>
                    <br></br>
                    <Link to="/about#fullcredit"><button className='button'> Full Credits</button></Link>
                </div>
                </div>
                </div>
                </div>     
        </div>
  );
};

export default Home;