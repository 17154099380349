export const GoDeeperItems = {
    "ep0": {
        preambleHTML: "",
        slideshowsList: [
            {
                slideshowId: "ep0_A",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd0_01",
                        number: "1",
                        filename: "01_teachingPrinciplesList.webp",
                        altText: "list of teaching epiphanies",
                        blurb: "So, let's get started! ...",
                        descriptionHTML: `<p>So, let's get started! In the <span class="text-italic">About</span> section, I noted the 7 ideas that the <span class="text-italic">Play Seriously</span> series explores are drawn from a longer list of "teaching epiphanies" &mdash; things that I think I have learned about teaching. It's a list that I have added to year-on-year as part of my own teaching improvement process. Making the list helps me to crystalize and generalize ideas that I think are important in creating learning environments that inspire people to do their best.</p>
        
            <p>For what it's worth, here's additional idea sound-bites from that list &mdash;in no particular order. There's a fair bit behind each statement, so if any pique your interest, it would be fun to discuss them with you on our forum. Just ask.</p>
        
            <p>A main goal of <span class="text-italic">Play Seriously</span> is to bring the 7 chosen principles to life through real, in-class activites of students. Pretty much every class period in the 3 month term had activities that could be used to illustrate a given principle &mdash; so, working with Director Brian Cassin, I carefully picked a subset of class activities to follow. There were 12 filming days throughout the ~95 day 2023 term &mdash; what the budget could accomodate!</p>`
                    },
                    {
                        id: "gd0_02a",
                        number: "2a",
                        filename: "02a_KresgeFinalPrototypeLaunch.webp",
                        altText: "Kresge Auditorium during 2.009 Prototype Launch",
                        blurb: "Kresge Auditorium ...",
                        descriptionHTML: `<p>This is Kresge Auditorium on December 11, 2023 during the 2.009 final prototype launch.</p>`
                    },
                    {
                        id: "gd0_02b",
                        number: "2b",
                        filename: "02b_excitementInKresge.webp",
                        altText: "excitement in Kresge",
                        blurb: "An inspiring celebration ...",
                        descriptionHTML: `<p>An inspiring celebration for all, centered around what we do—design!</p>`
                    },
                    {
                        id: "gd0_03",
                        number: "3",
                        filename: "03_KresgeStudioSetup.webp",
                        altText: "Kresge studio setup",
                        blurb: "And this is Kresge ...",
                        descriptionHTML: `<p>And this is Kresge on March 21, 2024, converted to a studio for recording the explanatory narrative for the series. After recording the class in action during the fall of 2023, the narration was recorded in Kresge over 4 days in March. A busy 4 days.</p>`
                    },
                    {
                        id: "gd0_04",
                        number: "4",
                        filename: "04_cameras6k.webp",
                        altText: "cameras in Kresge",
                        blurb: "Wow, those are ...",
                        descriptionHTML: `<p>Wow, those are fancy cameras! For Play Seriously, our goal was to produce a high quality series with production that will stand the test of time. Everything—even the classroom footage—was recorded in high resolution 6K!</p>`
                    },
                    {
                        id: "gd0_05",
                        number: "5",
                        filename: "05_settingLightingUp.webp",
                        altText: "lighting setup in Kresge",
                        blurb: "Studio filming ...",
                        descriptionHTML: `<p>Studio filming involves a lot of test, sit around and wait, and test again—paying attention to and caring for the smallest of details. You'll hear more about that notion in Episode 1! Here, I'm feeling the thrill of posing while lighting adjustments are made.</p>`
                    },
                    {
                        id: "gd0_06",
                        number: "6",
                        filename: "06_scriptDiscussion.webp",
                        altText: "script discussion",
                        blurb: "And here we're ...",
                        descriptionHTML: `<p>And here we're noodling out a recording plan. Director Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> and I are thinking through the first part of the series introduction while props are being set up. The auditorium was heated, and Brian's a warm guy, so I'm not sure why he dressed like it's below freezing!</p>
        
            <p>I had written a script to follow, but, like in life, many good things often come in the moment—when you are open to new opportunities.</p>`
                    },
                    {
                        id: "gd0_07a",
                        number: "7a",
                        filename: "07a_introductionCrabStoryboard_p1.webp",
                        altText: "introducing the crab storyboard",
                        blurb: "Anyone who has been in ...",
                        descriptionHTML: `<p>Anyone who has been in a class I teach will have heard about storyboards—a tool for designing presentations, product user experiences, and yes...films! A visual script. Here's part of a storyboard Brian <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> made to think about the crab mobile scene.</p>`
                    },
                    {
                        id: "gd0_7b",
                        number: "7b",
                        filename: "07b_introductionCrabStoryboard_p2.webp",
                        altText: "crab storyboard panel",
                        blurb: "The final entry ...",
                        descriptionHTML: `<p>The final entry onto the stage.</p>`
                    },
                    {
                        id: "gd0_08a",
                        number: "8a",
                        filename: "08a_crabInKillian.webp",
                        altText: "crab mobile in Killian Court",
                        blurb: "We filmed out-and-about ...",
                        descriptionHTML: `<p>We filmed out-and-about campus with the crab mobile for the introduction sequence—here at the symbol of MIT, Killian Court.  It was a drizzly, wet day, but that didn't dampen our ability to play seriously. We were on task, focused, and having fun.</p>`
                    },
                    {
                        id: "gd0_8b",
                        number: "8b",
                        filename: "08b_davidInKillian.webp",
                        altText: "Prof Wallace in Killian",
                        blurb: "Filming out-and-about ...",
                        descriptionHTML: `<p>Filming out-and-about also involves a lot of test, stand around and wait, and test again!</p>`
                    },
                    {
                        id: "gd0_09",
                        number: "9",
                        filename: "09_crabBlocking.webp",
                        altText: "crab mobile in Kresge",
                        blurb: "Back in Kresge ...",
                        descriptionHTML: `<p>Back in Kresge, we're figuring out blocking for driving the crab mobile onto the stage.  When you watched the series introduction, did you notice that the crab was pretty wrinkly? It's quite visible on stage.  And no, the crab wasn't molting—the printed paper graphic was wrinkled because it was wet from filming outdoors in the rain. Wet paper expands a lot, plywood (the crab's substrate) not-so-much. Voila, wrinkles are born. Arg, such is life.</p>`
                    },
                    {
                        id: "gd0_10",
                        number: "10",
                        filename: "10_crewInAction.webp",
                        altText: "filming crew in action",
                        blurb: "Director Brian Cassin and ...",
                        descriptionHTML: `<p>Director Brian Cassin <a href="https://www.instagram.com/innerparakeet" target="_blank">@innerparakeet</a> and Director of Photography Matthew Dorris <a href="https://www.instagram.com/filouza" target="_blank">@filouza</a> and 1st Assistant Camera Asa Reed <a href="https://www.instagram.com/asareed" target="_blank">@asareed</a> filming the grand entrance!</p>
        
            <p>And for now, that's the introduction's backstory. Up next, <a href="/#ep1">Episode 1: Everything is an example</a>.</p>`
                    },
                ]
            }
        ]
    },
    "ep1": {
        preambleHTML: `<p>In episode 1, I used the 2.009 build challenge activity from fall 2023 to demonstrate the principle <span class="text-italic">everything is an example</span>, and to highlight how attending to every detail with care leads to something special &mdash; something that truly stands apart.  So, in this edition of <span class="text-italic">Back Stories</span>, I thought it would be interesting to dive into the build challenge itself.</p>

        <ol class="gd-abc-list">
            <li>What is the build challenge? Why do we do it? How do we do it?</li>
            <li>The 2023 build challenge. What was its story? What are the vehicle designs?</li>
            <li>Why does the build challenge change every year?</li>
        </ol>

<p>I'll also include videos and vehicle sketches for the build challenges that are discussed. And, if you have other thoughts or other questions, please join in our <a href="https://forum.playseriously.com" target="_blank">forum</a> and I'll respond as best that I can.</p>

<p>All right! Let's get started!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep1_A",
                slideshowTitle: "A) What is the build challenge? Why do we do it? How do we do it?",
                slidesData: [
                    {
                        id: "gd1_A01",
                        number: "A1",
                        filename: "A01_theBuildChallenge.webp",
                        altText: "build challenge field",
                        blurb: "The build challenge! ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">What is the build challenge? Why do we do it? How do we do it?</p>
<p>The build challenge! As I said in episode 1, "It's extreme, it pushes limits, it's kind of crazy, it's euphoric. It's fun."</p>

<p>Fun indeed. Super important. But is that alone enough? My answer is... no! In teaching, I think it's important to think carefully about the lesson I'm trying to convey, and that every teaching action needs to somehow contribute to the mission of the learning experience. If something is included only because I think it's "cool", it's not much more than a "stunt" or "party trick", so to speak.</p> 

<p>I'm often asked why we don't promote the build challenge as a campus event. I don't because I want the students to know that it is for them and their learning experience alone. Nothing more.</p>`
                    },
                    {
                        id: "gd1_A02",
                        number: "A2",
                        filename: "A02_loveWhatWeDo.webp",
                        altText: "tired team",
                        blurb: "In the picture, we see ...",
                        descriptionHTML: `<p>In the picture, we see a tired, happy, and fulfilled Purple Team in the lab after a major prototyping milestone.</p> 

<p>I've said that my teaching goal is to build an audacious willingness to love what we do (which is product design), to learn how to work together, and to do-what-we-do well.</p>`
                    },
                    {
                        id: "gd1_A03",
                        number: "A3",
                        filename: "A03_ideateModelTest.webp",
                        altText: "ideate model test",
                        blurb: "The build challenge reflects ...",
                        descriptionHTML: `<p>The build challenge reflects this ideal; but, more specifically, I'm hoping to help students fully embrace the process of designing.</p>

                        <ul class="gd-no-bullet-list">
<li>Ideate &mdash; freely coming up with lots of ideas.</li>
<li>Model &mdash; building prototypes that represent ideas. <span class="text-italic">And</span></li> 
<li>Test! &mdash; using your models to experiment and learn.</li>
</ul>

<p>And then to start all over again, improving your ideas. Ideate. Model. Test!</p>`
                    },
                    {
                        id: "gd1_A04",
                        number: "A4",
                        filename: "A04_teamsAssembleVehicleInLab.webp",
                        altText: "team assembling vehicle",
                        blurb: "The build challenge is just ...",
                        descriptionHTML: `<p>The build challenge is just a low stakes practice run for this design cycle. Ideate. Model. Test! It runs in parallel to when student teams are executing their first design iteration for the real, "serious" products that they will conceive. This first iteration takes place over a three week cycle near the start of the term.</p>`
                    },
                    {
                        id: "gd1_A05",
                        number: "A5",
                        filename: "A05_vehicleConceptsInClass.webp",
                        altText: "students brainstorming in class",
                        blurb: "Day 1, Students Ideate! ...",
                        descriptionHTML: `<p class="text-italic">Day 1, Students Ideate!</p>

<p>Just as students are about to begin generating ideas for their products, we spend 30 minutes of class time practicing &mdash; individually ideating "silly vehicles" to complete a whimsical mission. That is, the build challenge's team relay-race mission!  The mission of the build challenge is usually an allegorical story connected to the year's "serious" product development theme. (I'll give you a specific example, in <span class="text-yellow">B4</span>, when I explain the story of the 2023 build challenge featured in episode 1.)</p>

<p>Then off the lab students go, working with their teams ideating for their real products! Meanwhile, behind the scenes, I typically work with former 2.009 student/teaching assistant/lecturer Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> sorting through all the student's ideas. Together we pick one interestingly ridiculous vehicle for each team. Then, I quickly flesh out each team's vehicle design, just enough to establish principles of propulsion. After discussing with the Pappalardo lab staff <a href="https://www.instagram.com/pappalardolab" target="_blank">@pappalardolab</a> and the class teaching assistants to get feedback, it's time to design the vehicles for real. I usually do this in a 24-30 hour marathon &mdash; sketching, sourcing parts, and working out details all at the same time.</p>`
                    },
                    {
                        id: "gd1_A06",
                        number: "A6",
                        filename: "A06_redTeamVehicleComponents.webp",
                        altText: "vehicle components",
                        blurb: "Day 10 ...",
                        descriptionHTML: `<p class="text-italic">Day 10</p>

<p>Pappalardo staff, teaching assistants, and a host of mentor volunteers spend a weekend together in the lab to make IKEA-style vehicle kits for each team. All together, about 30 kind folks help in the vehicle kit-building process. On this weekend, I also do the instruction sketches that the students will follow when they assemble their vehicles.</p>`
                    },
                    {
                        id: "gd1_A07",
                        number: "A7",
                        filename: "A07_purpleTeamBuildingVehicle.webp",
                        altText: "team building vehicle",
                        blurb: "Day 14, Model! ...",
                        descriptionHTML: `<p class="text-italic">Day 14, Model!</p>

<p>Just as student teams are about to start making exploratory models of their product ideas &mdash; very quick-and-dirty prototypes that we call <span class="text-italic">sketch models</span> &mdash; students spend a one hour class period in the lab building their vehicles from the kits that have been prepared for them. They get comfortable working in their team space, working with their tools, working with each other, and working on a timeline.</p>

<p>The vehicles provide examples of sketch-model-level prototyping; and, in making them, students are primed to work as a team on sketch models for their "serious" product ideas. Students are given this experience without distracting or taking time away from their primiary mission &mdash; to design a well-conceived product. It's just an hour of lecture time.</p>`
                    },
                    {
                        id: "gd1_A08a",
                        number: "A8a",
                        filename: "A08a_teamPresentsResearchAtReview.webp",
                        altText: "team presenting research",
                        blurb: "Day 18 ...",
                        descriptionHTML: `<p class="text-italic">Day 18</p>

<p>In their first major product development milestone, student teams present several competing product ideas under consideration. They explain their ideas and provide related market research.</p>`
                    },
                    {
                        id: "gd1_A08b",
                        number: "A8b",
                        filename: "A08b_teamDemonstratesSketchModelAtReview.webp",
                        altText: "team demonstrating sketch model",
                        blurb: "Day 18 (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 18 (continued)</p>
<p>The students also demonstrate ideas and key operational principles using simple sketch models. They discuss what they have learned in this initial ideate-model-test design cycle.</p>

<p>The goal is to explore many options at a high level to determine which product directions have the greatest potential.</p>`
                    },
                    {
                        id: "gd1_A09a",
                        number: "A9a",
                        filename: "A09a_buildChallengeDay.webp",
                        altText: "build challenge field",
                        blurb: "Day 19, Test! (AKA Build challenge ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! (AKA Build challenge day!)</p>

<p>In the lecture period following the product milestone review, it's time to test their whimsical vehicles in the mission of the build challenge. Yes. the build challenge. Live in Killian Court. It's a fast, furious and fun team bonding experience.</p>`
                    },
                    {
                        id: "gd1_A09b",
                        number: "A9b",
                        filename: "A09b_buildChallengeDay.webp",
                        altText: "team bonding at build challenge",
                        blurb: "Day 19, Test! (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! Build challenge day! (continued)</p>
<p>Who cares who wins? It's a cathartic release to cap off the completion of their first <span class="text-italic">Ideate. Model. Test!</span> cycle. And, ironically, this silly challenge and its level of execution clearly says to the students "This is different. Our expectation is to do things that are exceptional, and together we can realize the extra-ordinary".</p>`
                    },
                    {
                        id: "gd1_A10",
                        number: "A10",
                        filename: "A10_finalNightOfSetup.webp",
                        altText: "night before build challenge",
                        blurb: "The playing field! ...",
                        descriptionHTML: `<p>And, oh yes, the build challenge playing field! The course in Killian Court that the students navigate with their vehicles, racing to complete the build challenge's mission. So far, we've only talked about the students' vehicles. Here it is, in the final setup stages, in the wee-hours of the night before the challenge.</p>`
                    },
                    {
                        id: "gd1_A11",
                        number: "A11",
                        filename: "A11_playingFieldLayout.webp",
                        altText: "build challenge playing field",
                        blurb: "In August, typically, I ...",
                        descriptionHTML: `<p>In August, typically, I work with Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> and the course teaching assistants to define the challenge for the year, and to outline a concept for the playing field. While Victor and his team work on the major playing field set pieces, class TAs and I work on all the bits needed to make the event sing. Team T-shirts, custom hard hats, making individualized plushies for students, custom designed chocolate coins, goodies, instructions, costumes, event logistics, photography, spirit awards, the 2.009 build challenge cup, videos &mdash; the list goes on-and-on. Everything is designed to be an example.</p>`
                    },
                    {
                        id: "gd1_A12",
                        number: "A12",
                        filename: "A12_playingFieldSetupTimelapse.webp",
                        altText: "build challenge playing field setup",
                        videoInfo: {
                            playbackId: "u4xr2I2GDSJscKSoaZSaxAOKxesEYp7iygc9TUhoqLU",
                            title: "Build Challenge Behind the Scenes Timelapse",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "gd1_A12_build_challenge_timelapse",
                                video_title: "Build Challenge Behind the Scenes Timelapse",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "And how do all of these ...",
                        descriptionHTML: `<p>And how do all of these details get thought about and then addressed with great care? As my advisor Woodie Flowers said, "play the movie in your head". I painstakingly experience the entire challenge and its preceeding weeks in my head, as detailed and step-by-step as I can, carefully noting what needs to happen and when. Then one must build a team and delegate, working with individuals who treat every element to which they contribute, big or seemingly very small, with ownership, careful thought, and the utmost of importance. Folks that "play the movie in their head", too.</p>

<p>The challenge is set up in Killian Court over one day and night &mdash; so it is a surprise for students when they arrive. All together, well over 1500 person hours (not including the student's time) and the help of nearly 100 people is needed to make the challenge happen. Thank you helpers over the years!</p>

<p>The challenge is designed to provide a practice <span class="text-italic">ideate. model. test!</span> learning experience that is meaningful, playful, and does not compete with or distract from the students' "serious" product development mission. Instead, it actually augments and sharpens their focus.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_B",
                slideshowTitle: "B) The 2023 build challenge. What was its story? What are the vehicle designs?",
                slidesData: [
                    {
                        id: "gd1_B01",
                        number: "B1",
                        filename: "B01_wildAssociationMap.webp",
                        altText: "association map for Wild",
                        blurb: "Product development theme ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The 2023 build challenge. What was its story? What are the vehicle designs?</p>
<p>The product development theme in the fall was <span class="text-italic">Wild!</span> Exciting, untamed, and incredible. Products that give us the feeling of "wow, that was wild!". This association map for <span class="text-italic">wild</span> was provided to students as a launching pad to think for themselves about what types of products the theme of <span class="text-italic">wild</span> might inspire.</p>`
                    },
                    {
                        id: "gd1_B02",
                        number: "B2",
                        filename: "B02_mildyGuidedByMissChiefs.webp",
                        altText: "Mildy guided by Miss Chiefs",
                        blurb: "How did the build challenge ...",
                        descriptionHTML: `<p>How did the build challenge relate to <span class="text-italic">wild</span>? It's a story of transformation, how the character "Mildy", oppressed by the Bureau of Boredom (BoB), is transformed through the efforts of critters called Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B03",
                        number: "B3",
                        filename: "B03_WildyAndMissChiefs.webp",
                        altText: "Wildy and Miss Chiefs",
                        blurb: '"Mildy" transformed into "Wildy" ...',
                        descriptionHTML: `<p>"Mildy" transformed into "Wildy" through the power of the Miss Chiefs, in the wild!  "Wildy" &mdash; free to think and dream big!</p>

<p>I play Mildy and Wildy, and the students are the Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B04",
                        number: "B4",
                        filename: "B04_2023BuildChallenge.webp",
                        altText: "2023 build challenge",
                        videoInfo: {
                            playbackId: "TZLo00OU65a01v02UHUNzYHi5PaEYebTU7SXM2adx024sGY",
                            title: "2023 Build Challenge - Into the Wild",
                            thumbnailTime: "5",
                            metadata: {
                                video_id: "gd1_B04_2023_build_challenge",
                                video_title: "2023 Build Challenge - Into the Wild",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "The build challenge video ...",
                        descriptionHTML: `<p>The build challenge video, prepared for showing at the 2023 2.009 final prototype launch event, will help you understand the flow of the challenge. At 1:15 in the video, there is a fun animation that explains the story of the build challenge mission. Enjoy!</p>`
                    },
                    {
                        id: "gd1_B05",
                        number: "B5",
                        filename: "B05_2023BuildVehicles.webp",
                        altText: "2023 build challenge vehicles",
                        blurb: "And, as promised, here are ...",
                        descriptionHTML: `<p>And, as promised, here are sketches of each team's vehicle for the 2023 build challenge. Download <a href="/back_stories/ep1/B05_2023BuildVehicles.pdf" target="_blank">PDF</a></p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_C",
                slideshowTitle: "C) The build challenge changes every year! But why?",
                slidesData: [
                    {
                        id: "gd1_C01",
                        number: "C1",
                        filename: "C01_2018dangerBuildChallenge.webp",
                        altText: "2018 build challenge",
                        blurb: "Changing allows the story ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The build challenge changes every year! But why?</p>
                        <p>Changing allows the story of the challenge to reinforce each year's inspirational product development theme (which also changes every year). And this way students of each year know that it is <span class="text-italic">their</span> challenge. It is unique for them. This image is from 2018, when the year's design theme was <span class="text-italic">Danger</span>. The story of the build challenge pitted David Danger against Sir Safety, representing the fine balance of openly taking risks and also safe-guarding the well-being of those who we design for!</p>

<p>And, of course, by changing every year it lets us, the staff, try new things and improve, too! It's fun!</p>

<p>If the same challenge was dusted-off each year &mdash; which indeed is a lot less work &mdash; would we not be setting an example of being complacent, of standing still? Is that an example of excellence?</p>`
                    },
                    {
                        id: "gd1_C02",
                        number: "C2",
                        filename: "C02_2017buildChallenge.webp",
                        altText: "2017 build challenge",
                        videoInfo: {
                            playbackId: "27ygudoO9r1Qu7cBsrvkdpeizraUq4w6yxmLR00diiQA",
                            title: "2017 Build Challenge - Product Man",
                            thumbnailTime: "43",
                            metadata: {
                                video_id: "gd1_C02_2017_build_challenge",
                                video_title: "2017 Build Challenge - Product Man",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "A different build challenge ...",
                        descriptionHTML: `<p>Here's an example of a build challenge from a different year.</p>

<p>It's the fall of 2017. <span class="text-italic">Super</span> is the theme! It's the year of spontaneously combusting cell phones and the Juicero! Students use their super powers to rescue Superhero Product Man and defeat Dr. Defective, battling bad design!</p>`
                    },
                    {
                        id: "gd1_C03",
                        number: "C3",
                        filename: "C03_2017BuildVehicles.webp",
                        altText: "2017 build challenge vehicles",
                        blurb: "Sketches of 2017 build ...",
                        descriptionHTML: `<p>Here are sketches of 2017 build challenge vehicles. Download <a href="/back_stories/ep1/C03_2017BuildVehicles.pdf" target="_blank">PDF</a></p>

<p>If you'd like to see more, you can find many years' build challenge videos in the <a href="http://designed.mit.edu/new/index.html" target="_blank">2.009 gallery</a> (look for the build challenge links).</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_D",
                slideshowTitle: "D) Ideate! Model! Test! Repeat.",
                slidesData: [
                    {
                        id: "gd1_D01",
                        number: "D1",
                        filename: "D01_2023ideateModelTestRepeat.webp",
                        altText: "ideate model test repeat",
                        videoInfo: {
                            playbackId: "Z5bZp6c01NZCObpsqdxz651ZqS7xv9eb18dOvgOKINZ4",
                            title: "Ideate! Model! Test! Repeat.",
                            thumbnailTime: "38",
                            metadata: {
                                video_id: "gd1_D01_2023_IMTR_video",
                                video_title: "Ideate! Model! Test! Repeat.",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "What's the mantra? ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">Ideate! Model! Test! Repeat.</p>

<p>And finally, what's the build challenge design mantra? <span class="text-italic">Ideate! Model! Test! Repeat.</span> Join Mildy and Wildy in the animated musical!</p>`
                    },
                ]
            },
        ]
    },
    "ep2": {
        preambleHTML: `<p>It's time for backstories about episode 2. I'm excited!</p>

<p><span class="text-italic">Have a plan, and be ready to change it.</span> That's hard. As designers, engineers, and doers in general, we have an idea &mdash; a plan. We need to fully believe in it and be all-in. Invested! But, at the same time, we need to be reflective, open to seeing limitations, and decisively change paths when better opportunities or solutions arise. How do we let go? How are we open to change, but also avoid bouncing around between options every time a difficulty arises? Should we persist on our path, or change course? How do we embrace a superior option that was somebody else's suggestion?</p>

<p>Wowee, this is heady stuff, but I do believe that <span class="text-italic">having a plan and being ready to change it</span> is essential for excellence. To keep growing. To keep improving to meet our goals. To be a good designer! We can always be better.</p>

<p>So, let's dig in!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep2_A",
                slideshowTitle: "A: The origins of FNAP",
                slidesData: [
                    {
                        id: "gd2_A01",
                        number: "A1",
                        filename: "A01_workingOnLectureEarlyAM.webp",
                        altText: "Prof. Wallace in office in early morning",
                        blurb: "FNAP, the origin story! ...",
                        descriptionHTML: `<p>FNAP, the origin story!</p>

<p>It's between 2-3 AM of a fall 2017 morning. I'm working on lecture materials as the clock relentlessly ticks towards my deadline—a lecture starting at 1 PM of the same day. And I know something needs to be changed from last year. There's something that needs to be done better. Think!</p>

<p>It's the time in 2.009 where teams are about to make their final product choice, selecting from 4 separate opportunites on which subgroups (4-5 students) within each team have been working. Each team is roughtly 16-20 students.</p>

<p>The team has been working on four different options in parallel, ensuring rigour in the exploration of opportunities and increasing the odds of having a really promising final product direction.</p>`
                    },
                    {
                        id: "gd2_A02",
                        number: "A2",
                        filename: "A02_inLecture.webp",
                        altText: "a 2.009 lecture",
                        blurb: "And students have also been working ...",
                        descriptionHTML: `<p>And students have also been working in parallel subgroups to set up a powerful and very challenging learning experience! How does four sub-groups, each invested in a different option, come together as a single team and make an informed choice about what option has the greatest promise? How does each team member assess options with as little bias as possible, independent of who has worked on it?</p>

<p>In prior years, I'd try to prepare students by talking about being fact/data driven, being open to listening and internalizing different points of view. Really important. And hard! Sometimes it's really hard to know what's actually known fact vs what is simply believed. Without understanding that, how can risks and rewards be assessed? Strong beliefs, disguised as facts, can hold sway.</p>`
                    },
                    {
                        id: "gd2_A03",
                        number: "A3",
                        filename: "A03_difficultChoicesInLab.webp",
                        altText: "student in lab",
                        blurb: "So the elephant in the room ...",
                        descriptionHTML: `<p>So the elephant in the room during these decision meetings? Thoughts like... does that teammate really know this, or are they hoping that and mostly selling what they worked on? Or perhaps thoughts like... do I really know what I am stating as fact, or am I overreaching in claims because of my personal investment in the idea? These are questions that folks don't want to vocalize!</p>

<p>So, yes, difficult questions. I've long thought that pretending such concerns don't exist can lead to less than ideal outcomes.</p>

<p>So, I wanted to have a way to get concerns and different opinions out into the open. I wanted to find a way to make it socially acceptable to have an open discussion <span class="text-italic">in our own mind</span>. What in our thinking is fact, and what is hope or a hunch? And I wanted to provide a device that allowed teams to openly question how well something is understood, and how much is wishful thinking? To flag what seemed more like conjecture. How could such honest questioning become an accepted matter-of-course, a norm, in doing their business?</p>`
                    },
                    {
                        id: "gd2_A04",
                        number: "A4",
                        filename: "A04_FNAPinLab.webp",
                        altText: "FNAP posters in lab",
                        blurb: "What did we need? ...",
                        descriptionHTML: `<p>What did we need? My answer... an acronym! A catchy mnemonic that made it OK to openly wonder and ask out loud in a team setting. A mnemonic to remind ourselves to clearly represent what is fact as fact, and identify what we hope as hopes. To remind ourselves that having a confident hope (assertion) simply means there is more work to done before it becomes a fact (or not!).</p>

<p>So after a few minutes of noodling, FNAP (Facts Not Assertions, Please!) came to mind! Into the lecture it went, and onto the walls in the lab. Folks smiled, it stuck, and FNAP was born!</p>`
                    },
                    {
                        id: "gd2_A05",
                        number: "A5",
                        filename: "A05_MAplate.webp",
                        altText: "MA license plate",
                        blurb: "Acronyms and license plates...",
                        descriptionHTML: `<p>Acronyms and license plates. The origin story's origin. Or perhaps just a bit of an aside.</p>

<p>Here's a little game I've played to pass time while in traffic. I look at the plate on the car in front of me. As long as I can see that plate and as fast as I can, I think of different things that the letters on the plate could be an acronym for—the more amusing the better! When the plate goes out of sight, it's on to the next plate.</p>

<p>My favorite Massachusetts plates have the format 123-ABC or 1ABC-23. I prefer a three letter format. The game works for two letter plates, too, or even word associations for a single letter. But, I find three letters the most fun.</p>

<p>I'd like to believe that my big payoff for years of license-plate acronym-training was the creation of FNAP, under the pressure of a deadline!</p>`
                    },
                    {
                        id: "gd2_A06",
                        number: "A6",
                        filename: "A06_FNAP_IntroAnimation.webp",
                        altText: "FNAP animation",
                        videoInfo: {
                            playbackId: "67tYGfjDkXCkh6Yplh02IPcxz02kMwqpeKQ00cNNnfBUDs",
                            title: "FNAP animation",
                            thumbnailTime: "3.4",
                            metadata: {
                                video_id: "gd2_A06_FNAP_animation",
                                video_title: "FNAP animation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "FNAP, the video! ...",
                        descriptionHTML: `<p>FNAP, the video!</p>

<p>During the pandemic I ended up making a number of video-based lectures for 2.00b (toy product design) and for 2.009. So back in person, in the fall of 2020 and spring of 2021, I started making animated shorts to kick-off the start of lectures in a positive way, while reinforcing an idea that I hoped would stick.</p>

<p>FNAP was a natural fit. In the fall of 2021, the FNAP animation was created in Blender—with Director Brian Cassin <a href="https://instagram.com/innerparakeet" target="_blank">@innerparakeet</a>, modeling help from graduate student Charlene Xia, and featuring the song writing and composing of longtime 2.009 friend Sam Watkinson  <a href="https://instagram.com/sam100watkinson" target="_blank">@sam100watkinson</a>. For the song vocals, Brian sent quick voice memo on Slack to Sam suggesting ideas about how the song might go. It began with "maybe something like this...". And that recording became the audio sample in the song!</p>

<p>Please take a look!</p>

<p>(You may have also watched the <a href="/BackStories/ep1#ep1_D">ideate-model-test</a> animation at the end of the back stories for episode 1. It was also developed as a lecture kickoff animation by the same team.)</p>`
                    },
                    {
                        id: "gd2_A07",
                        number: "A7",
                        filename: "A07_episodeIntroStoryboard.webp",
                        altText: "episode storyboard",
                        blurb: "The FNAP song ...",
                        descriptionHTML: `<p>The FNAP song turned out to be quite catchy, so we decided to use it to lead off the arcade-game dance-scene for this episode, <span class="text-italic">have a plan and be ready to change it</span>.</p>

<p>Director Brian Cassin's rough storyboard was used to guide the filming of the episode's introduction. The "game console" is something that we had originally made for use in the 2.009 shop &mdash; a kiosk for purchasing materials from our in-lab store &mdash; repurposed with FNAP graphics!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep2_B",
                slideshowTitle: "B: Decisions",
                slidesData: [
                    {
                        id: "gd2_B01",
                        number: "B1",
                        filename: "B01_mediationWithMindy.webp",
                        altText: "mediation with Mindy",
                        blurb: "FNAP is a little reminder ...",
                        descriptionHTML: `<p>FNAP is a little reminder, a voice for our conscience, to help us stay grounded in our own thinking and when making choices with others.</p>

<p>But, is FNAP alone all that is needed to make your best decisions?  Probably not!</p>

<p>To support student teams in their 2.009 decisions there are a plethora of resources in place. We provide teams with an <a href="https://2023.2009.mit.edu/team-manual/team-roles" target="_blank">officer structure</a> that helps with management (system integrators) and team-member needs (yodas &mdash; an exciting title for a human resource position). In their labs, teams also have experienced faculty instructors, communication instructors, and mentors with product development experience. Plus, Mindy Garber, MechE alum, software developer, and professional mediator (shown above), holds a workshop on Moderating Design Disputes. Thanks, Mindy!</p>

<p>So will FNAP and all these support resources consistently lead to our best team choices? They sure do help, but still probably not enough!</p>

<p>What else do we need? Drum roll, please!... We need a decision process, and we need an acronym to remember it!</p>`
                    },
                    {
                        id: "gd2_B02",
                        number: "B2",
                        filename: "B02_GoodDay.webp",
                        altText: "sunny sky",
                        blurb: "G'DAY! ...",
                        descriptionHTML: `<p>G'DAY!</p>

<p>Another made up acronym, just like FNAP. It's to remind teams of the steps I'd like them to follow when making choices &mdash; and in particular the very difficult team choice of narrowing from 4 product options to their final direction.</p>

<p><span class="text-yellow" style="font-weight:bold;">G</span>ive. <span class="text-yellow" style="font-weight:bold;">D</span>ecide. <span class="text-yellow" style="font-weight:bold;">A</span>ccept. <span class="text-yellow" style="font-weight:bold;">Y</span>o-wup!</p>

<p>(This graphic is from actually one of my lecture slides in 2.00b &mdash; we want every lecture be a g'day, too!)</p>`
                    },
                    {
                        id: "gd2_B03",
                        number: "B3",
                        filename: "B03_ExplainingToTeam.webp",
                        altText: "team discussion",
                        blurb: "Give. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">G</span>ive.</p>
<p>Our first job is to freely give information and express our thoughts. With FNAP on our minds, we want to ensure that all members of the team are well informed about the options. We want to build an agreed upon understanding of where an option is at, what's its potential, what's the likely road ahead, and what are its risks.</p>

<p>We need to teach each other about all the options. We know what we've worked on ourselves, but much less about what others have done. I'll often ask sub-groups to be responsible for teaching the team about an option that they themselves did not work on! So they first need to meet with the group that worked on the option, learn about it, and then come to an agreement on how to factually GIVE the information about the option to the rest of the team!</p>

<p>Once the team has been well informed of the options, the team needs to agree that they are sufficently informed and understand what is fact, and what are hopes and beliefs. The knowns and unknowns.</p>`
                    },
                    {
                        id: "gd2_B04",
                        number: "B4",
                        filename: "B04_greenStartingPugh.webp",
                        altText: "pugh charting",
                        blurb: "Decide. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">D</span>ecide.</p>
<p>Like it or not, we are a point in our product development process where we need to commit. To decide on our most promising option as a final product direction. If we don't, we're going to miss our delivery date!</p>

<p>There are lots of decision tools that might be used. We often use a Pugh chart and then vote. It's a simple tool to visually compare options and facilitate a concrete discussion summarizing goals, pros, and cons, before selecting.</p>`
                    },
                    {
                        id: "gd2_B05",
                        number: "B5",
                        filename: "B05_greenAfterDecision.webp",
                        altText: "happiness after making decision",
                        blurb: "Accept. ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">A</span>ccept.</p>
<p>And when is a decision made?</p>

<p>When there's consensus that the decision is made, and we can live with it. Given all the unknowns at this stage, there will almost always be differing opinions about what's the best product opportunity.  That's fine, but it's important that team members agree that the choice has been made fairly and respectfully.</p>

<p>We all need to Accept the decision.</p>`
                    },
                    {
                        id: "gd2_B06",
                        number: "B6",
                        filename: "B06_tejimeExample.webp",
                        altText: "Japanese tejime example",
                        videoInfo: {
                            playbackId: "YgEF6yuvUdM1m1DtxiGaVP02BGhBFZjMh8xLWMIdPEAg",
                            title: "Japanese tejime example",
                            thumbnailTime: "6",
                            metadata: {
                                video_id: "gd2_B06_JP_tejime",
                                video_title: "Japanese tejime example",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Yo-Wup! ...",
                        descriptionHTML: `<p class="text-italic"><span class="text-yellow" style="font-weight:bold;">Y</span>o-Wup!</p>

<p>Yo-What? We need something to symbolize that we have come to a decision about our product, and to mentally move on united in our mission. A cathartic way to change gears and look forward together.</p>

<p>And for this, I've respectfully adopted a Japanese hand-clapping ritual called <a href="https://en.wikipedia.org/wiki/Tejime" target="_blank">Tejime</a>! It's a cathartic release which signifies that an important decision has been made, or an important event has occured, and we are moving forward. Closure. We are united.</p>

<p>And I believe that it really works.</p>`
                    },
                    {
                        id: "gd2_B07",
                        number: "B7",
                        filename: "B07_blueTeamProductDecisionTejime.webp",
                        altText: "2.009 team tejime example",
                        videoInfo: {
                            playbackId: "TonIkdgmcqtSHrxgUsMKr6p95brpa8JYbpSbA2V01AGQ",
                            title: "2.009 blue team tejime",
                            thumbnailTime: "2",
                            metadata: {
                                video_id: "gd2_B07_team_tejime",
                                video_title: "2.009 blue team tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "I set up context for the Tejime ...",
                        descriptionHTML: `<p>I set up context for the Tejime in lecture. As a class in lecture &mdash; we practice!
And here's Blue Team's yo-wup after a 3 hour process to decide upon their final product direction! It brings a smile, a feeling of accomplishment, and a release after a difficult process.</p>`
                    },
                    {
                        id: "gd2_B08",
                        number: "B8",
                        filename: "B08_finalYoWup.webp",
                        altText: "2.009 final prototype launch tejime example",
                        videoInfo: {
                            playbackId: "pXMwQ8jjHZSW8OeDn8201OeBny1GndyUgryGZ501FqqNo",
                            title: "2.009 final prototype launch tejime",
                            thumbnailTime: "26.73",
                            metadata: {
                                video_id: "gd2_B08_final_launch_tejime",
                                video_title: "2.009 final prototype launch tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "So, that's the decision process. ...",
                        descriptionHTML: `<p>So, that's the decision process.</p>
<p><span class="text-yellow" style="font-weight:bold;">G</span>ive. <span class="text-yellow" style="font-weight:bold;">D</span>ecide. <span class="text-yellow" style="font-weight:bold;">A</span>ccept. <span class="text-yellow" style="font-weight:bold;">Y</span>o-wup! Follow these steps and you'll have a <span class="text-yellow" style="font-weight:bold;">G'day</span>! Really!</p>

<p>Now, if there's a 2.009 event meriting a yo-wup, this would be it &mdash; the end of 2.009 and the conclusion of our final prototype launch! Here we are in the fall of 2023, the same year that Play Seriously was recorded.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep2_C",
                slideshowTitle: "C is for Closure",
                slidesData: [
                    {
                        id: "gd2_C01",
                        number: "C1",
                        filename: "C01_HifanaTejime.webp",
                        altText: "Hifana Tejime",
                        videoInfo: {
                            playbackId: "dpLQTUvGAQe2aejGe4Vc2ke01NUOIP4G8TQL01DgUTxkk",
                            title: "Hifana Tejime",
                            thumbnailTime: "4",
                            metadata: {
                                video_id: "gd2_C01_Hifana_Tejime",
                                video_title: "Hifana Tejime",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "And to close out our episode 2 backstories, ...",
                        descriptionHTML: `<p>And to close out our episode 2 back stories, here's a video that I use at the end of the class where I introduce the Tejime and G'DAY. It's right before teams will be making their product choices in lab.</p>

<p>The video is by the Japanese band <a href="https://www.youtube.com/user/HifanaTube" target="_blank">Hifana</a>. It's their Tejime, bidding farewell to the Heisei era (1989-2019), a time when the transition from analog to digital music gave rise to new and innovative means of expression. And it's their goodbye to digital music. It's pretty fun!</p>

<p>You should be able to recognize the clapping ritual in it. I shortened the original video a bit for use at the end of class and added the Tejime cover at the start.</p>`
                    },
                    {
                        id: "gd2_C02",
                        number: "C2",
                        filename: "C02_2020FinalPresCountdown.webp",
                        altText: "Introduction animation for 2020 2.009 final prototype launch",
                        videoInfo: {
                            playbackId: "wGS5lKYSjtnUuHCjHiNy8gGlBC9XcJoUAzkofUCZ1s8",
                            title: "2020 2.009 Final Prototype Launch Introduction Animation",
                            thumbnailTime: "10",
                            metadata: {
                                video_id: "gd2_C02_FP_intro_animation",
                                video_title: "2020 2.009 Final Prototype Launch Introduction Animation",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Oh, and just one more video. ...",
                        descriptionHTML: `<p>Oh, and just one more video. This is the welcome animation to introduce the 2020 final prototype launch.</p>

<p>2020. The pandemic. 2.009 was one of the handful of classes in-person-on-campus that fall. Lots of dark hallways and unheated classrooms. Our design theme was for the year was <span class="text-italic">Kindness</span>, and you should be able to see and hear the Hifana and Tejime inspiration in this animation, especially at 1:00s. Yes, I'm a fan!</p>

<p>In addition to introducing the theme for the year, the animation also alludes to the measures taken to work together in person, in teams, in lab developing our products at the height of the pandemic. Medical-level PPE and health scans to access the lab! The animation was directed by former 2.009 teaching assistant Or Oppenhiemer (thanks Or!) with animation help by our 2020 teaching assistants (all former 2.009 students). The original music is by our 2.009 friend Sam Watkinson <a href="https://instagram.com/sam100watkinson" target="_blank">@sam100watkinson</a>.</p>`
                    },
                ]
            },
        ]
    },
    "ep3": {
        preambleHTML: `<p>Make students' stars shine! For teachers, this seems pretty obvious, no? But maybe it's not. As doers, we are always working to be masters of our craft and to showcase what we do well. To excel in what we do. And certainly for myself &mdash; just to survive as an MIT professor &mdash; I needed to pretty much single-mindedly commit to excellence and to doing the work that made my own star shine bright.</p>

<p>So how does one get past always driving hard to better oneself and showcasing it, to being just as driven while taking the back seat and providing opportunities for others? To help others' stars shine. To really teach.</p>`,
        slideshowsList: [
            {
                slideshowId: "ep3_A",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_A01",
                        number: "A1",
                        filename: "A01_starsShineInEyes.webp",
                        altText: "students with shining eyes",
                        blurb: "Quite early in my career, ...",
                        descriptionHTML: `<p>Quite early in my career, I was attending a guest lecture and thinking "this is great!" I was watching nuanced technique and was comparing with my own. I was marvelling at the speaker's talent and mastery. Thinking about how I might try to change. I excitedly walked out of the lecture, and exclaimed to a student "wasn't that great!" And the student's response? "No, I'm so discouraged. I'll never be able to do anything like that."</p>

<p>And then, a light went on in my head! Yes, to teach I really did need to excel at my craft, and always work to be better. And I needed to bring that mastery to students. But I needed to do it in a way that wasn't a showcase of my own talent or how much skill something required. I needed to present and explain in a way that didn't require mastery just to relate with the topic. To teach, I needed to make it feel accessible. I needed to teach in a way that allowed every student to believe they can do it too. Because they can. To believe that with effort, they would be just as masterful, or better. I needed to make challenging things seem matter-of-fact. Make the complex seem clear and simple. I needed to be really good with enthusiam, not pretense! I needed to have the self-confidence in my own abilities to help others feel that they will be the master!</p>

<p>It was after this guest lecture and student interaction that I wrote down into my notebook "my job is to make students' stars shine". And I can say with complete earnestness that the most fullfilling moments in my professional experience have been seeing that transition when students' stars turn on and shine bright. In this picture, backstage, you can see the starlight in Pink team members' eyes. And that's when I feel my own star shine.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_B",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_B01",
                        number: "B1",
                        filename: "B01_gatesOfHell.webp",
                        altText: "natural gas crater - gates of hell",
                        blurb: "It's a fiery natural gas crater ...",
                        descriptionHTML: `<p>It's a fiery <a href="https://www.cnn.com/travel/turkmenistan-flaming-gates-of-hell-darvaza/index.html" target="_blank">natural gas crater in Turkmenistan</a> commonly referred to as the "gates of hell". Scary and captivating at the same time.</p>


<p>The gates of hell. Mythological entrances to the underworld. Historically, approaching the gates is oft associated with confronting peril in a noble quest.</p>

<p>OK, it's fiery. What does this have to do with <span class="text-italic">Play Seriously</span> and stars shining bright?</p>`
                    },
                    {
                        id: "gd3_B02",
                        number: "B2",
                        filename: "B02_wildMechanicalRevealGates.webp",
                        altText: "the 2.009 final prototype launch reveal gates",
                        videoInfo: {
                            playbackId: "V004E3Avf00etFvB9YYerM7NJNVSdy8AVKBKcUs6td00200",
                            title: '2.009 "Wild" Gates of Hell',
                            thumbnailTime: "38",
                            metadata: {
                                video_id: "wild_gates_of_hell",
                                video_title: '2.009 "Wild" Gates of Hell',
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Make students' stars shine bright features ...",
                        descriptionHTML: `<p><span class="text-italic">Make Students' Stars Shine Bright</span> features the 2023 2.009 final prototype launch event. The year's design theme is <span class="text-italic">Wild</span> and you saw the "wild" mechanical gates that opened to reveal teams on stage. These are the gates that we metaphorically call "the gates of hell".</p>

<p>The gates provide students with cover so they can set up upstage and be ready for a crisp, clean start to their presentation. Of course, students practice getting ready behind the gates, working out blocking and how to make their entrance at the moment of reveal. We (the staff) also need to practice opening and closing the gates to make it look smooth and easy. Synchronizing them and moving on a tight stage is actually not that easy! We have a short window to setup for the final launch event, so the gates are assembled, detailed, and undergo final debugging while students practice. There's a lot of parts moving at once.</p>`
                    },
                    {
                        id: "gd3_B03",
                        number: "B3",
                        filename: "B03_gatesClosed.webp",
                        altText: "the 2.009 reveal gates closed",
                        blurb: "Pink Team is about to begin. ...",
                        descriptionHTML: `<p>Pink Team is about to begin. Anticipation builds as team members are featured in video, while Pink Team readies behind the gates.</p>`
                    },
                    {
                        id: "gd3_B04",
                        number: "B4",
                        filename: "B04_gatesBehind.webp",
                        altText: "behind the 2.009 reveal gates",
                        blurb: "The gates of hell open for Blue Team. ...",
                        descriptionHTML: `<p>The gates of hell open for Blue Team. Students courageously face their fears in an inspired quest to launch their product prototype.</p>`
                    },
                    {
                        id: "gd3_B05",
                        number: "B5",
                        filename: "B05_gatesOpening.webp",
                        altText: "the 2.009 reveal gates opening",
                        blurb: "As the gates open, the students' stars ...",
                        descriptionHTML: `<p>As the gates open, the students' stars shine bright through the gate, spilling light into the yonder!</p>`
                    },
                    {
                        id: "gd3_B06",
                        number: "B6",
                        filename: "B06_gatesOpen.webp",
                        altText: "the 2.009 reveal gates open",
                        blurb: "Purple team steps through the gates. ...",
                        descriptionHTML: `<p>Purple team steps through the gates. Their light emanates through the knowledge that they have risen to their challenge and are transformed.</p>`
                    },
                    {
                        id: "gd3_B07",
                        number: "B7",
                        filename: "B07_blueTeamOnStage.webp",
                        altText: "team after presentation",
                        blurb: "The conclusion of Blue Team's prototype ...",
                        descriptionHTML: `<p>The conclusion of Blue Team's prototype launch presentation. They've courageously overcome challenges and succeeded in their quest, as a team. Indeed, their stars are shining.</p>

<p>So, that's the mechanical reveal gates. The gates of hell. Lights. Cryo. Kind of cool, yes? And now you know the idea that was behind them!</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_C",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_C01",
                        number: "C1",
                        filename: "C01_2.009toAvive.webp",
                        altText: "Avive AED device",
                        videoInfo: {
                            playbackId: "TNBBRw6sHjNNdI004Z602sZIwBaqSYrFEGP3zzuNufQZo",
                            title: "From 2.009 to Avive",
                            thumbnailTime: "97",
                            metadata: {
                                video_id: "from_2009_to_avive",
                                video_title: "From 2.009 to Avive",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Play Seriously focuses on the philosophy ...",
                        descriptionHTML: `<p><span class="text-italic">Play Seriously</span> focuses on the philosophy behind the effort to create learning environments where folks thrive and do good work. We use video from the class 2.009 to bring concepts to life. However, the ideas are widely applicable beyond this class, and so I didn't focus on the specifics of student work in this series. That said, when students' stars are shining, they do some pretty cool work. I figured that I would showcase some projects here, and will also showcase more in future episode backstories as well.</p>

<p>Here, I'll feature two products that are now commercially available. In our class, the focus is to set students up for the rest of their career. Our goal is to build a solid foundation and desire. That said, I often say that the best learning occurs when we "do it for real". We treat the learning experience as a design opportunity to do something real &mdash; that can make a difference. When a project from the class leads to a commercially available product, it's a bonus activity and just testament that the students are really doing it for real.</p>

<p>In this first video, we feature a product coming from Pink Team in the fall of 2016. In class, students named their product "Revive", and after the class students started the company <a href="https://avive.life" target="_blank">Avive</a> launching the product "QuickRescue". If you are on the MIT campus, you'll find their AED in many locations.</p>

<p>This video was shown during the pre-show of the 2.009 prototype launch in the fall of 2023.</p>`
                    },
                    {
                        id: "gd3_C02",
                        number: "C2",
                        filename: "C02_2.009to6dot.webp",
                        altText: "6dot braille label maker",
                        videoInfo: {
                            playbackId: "NeoJwKQ1penLIO6DQAaIk33zE7WMgGkP5LM23f9Fhuk",
                            title: "From 2.009 to 6dot",
                            thumbnailTime: "83",
                            metadata: {
                                video_id: "from_2009_to_6dot",
                                video_title: "From 2.009 to 6dot",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "Here's another commercial product ...",
                        descriptionHTML: `<p>Here's another commercial product that stemmed from Blue Team many years ago. It's a Braille labeler and, if you are interested, you can buy one!. It went from a 2.009 prototype named "dot-it", to a limited run product on Kickstarter, to being purchased and sold by an <a href="https://logantech.com/products/6dot-braille-label-maker" target="_blank">assistive device company under the name 6dot</a>.</p>

<p>This video was also produced and shown in the pre-show of the 2.009 prototype launch when we recorded <span class="text-italic">Play Seriously</span> (fall of 2023). The video also is a tribute to Paul Parravano, longtime MIT community member, who was a lead user for Blue Team on their project. He passed away shortly before the 2023 presentations. Paul's voice in the video comes directly from the team's class presentation.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep3_D",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd3_D01",
                        number: "D1",
                        filename: "D01_2016_FP_Kresge_Timelapse.webp",
                        altText: "Kresge setup before the presentations",
                        videoInfo: {
                            playbackId: "4ph7QTHRtLXwI2od4QWTqPTQB4MBH1DYK9jzuTWsFIc",
                            title: "2016 2.009 Final Presentation Kresge Setup Timelapse",
                            thumbnailTime: "19",
                            metadata: {
                                video_id: "2016_Kresge_setup_timelapse",
                                video_title: "2016 2.009 Final Presentation Kresge Setup Timelapse",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "To close it out, here is a time lapse video ...",
                        descriptionHTML: `<p>To close it out, here is a time lapse video of setting up for the prototype launch in Kresge, in December of 2016. It's a long time ago, and while a lot of things in the setup had changed by fall 2023, the founding ideas are similar. In the slideshow towards the end of the video, you certainly will see students' stars shining.</p>`
                    },
                ]
            },
        ]
    },
/*
    "ep2": {
        preambleHTML: ``,
        slideshowsList: [
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
        ]
    },
*/

}
